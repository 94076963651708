<template>
  <div class="page-coantainer">
    <el-dialog
      title="关联推广员"
      :visible.sync="promoterData.isOpen"
      width="60%"
      :before-close="handleClose"
    >
      <div class="dialog-body">
        <el-checkbox-group v-model="checkList">
          <el-checkbox
            v-for="item in promoterList"
            :label="item.id"
            :key="item.id"
            border
            size="small"
            >{{ item.name }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="bindPromoter">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getPromoterUserList, getListByUserId } from '@/api/api'
import { saveUserPromoter } from '@/api/login'
export default {
  props: {
    promoterData: {
      type: Object
    }
  },
  watch: {
    'promoterData.isOpen' () {
      this.userId = this.promoterData.id
      this.getPromoterList()
      this.getCheckedList(this.userId)
    }
  },
  data () {
    return {
      promoterIdList: [],
      userId: '',
      promoterList: [],
      checkList: []
    }
  },
  methods: {
    // 获取全部推广员名单
    getPromoterList () {
      const queryInfo = {
        currentPage: 1,
        pageSize: 99999
      }
      getPromoterUserList(queryInfo).then(res => {
        this.promoterList = res.data.list
      })
    },

    // 获取当前用户绑定的推广员名单
    getCheckedList () {
      this.checkList = []
      getListByUserId(this.userId).then(res => {
        res.data.map(item => {
          this.checkList.push(item.id)
        })
      })
    },

    // 绑定推广员
    bindPromoter () {
      this.promoterIdList = this.checkList

      saveUserPromoter({
        userId: this.userId,
        promoterIdList: this.promoterIdList
      }).then(res => {
        this.$emit('closeDialog', { isOpen: false, type: 'success' })
        this.$message({
          message: '绑定推广员成功',
          type: 'success'
        })
      })
    },

    // 关闭弹窗
    handleClose () {
      this.$emit('closeDialog', { isOpen: false })
    }
  }
}
</script>

<style></style>
