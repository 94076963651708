<template>
  <div class="page-coantainer">
    <el-dialog
      title="分配角色"
      :visible.sync="dialogData.isOpen"
      width="30%"
      :before-close="handleClose"
    >
      <div class="dialog-body">
        <el-form ref="form" label-width="80px">
          <el-form-item label="角色" prop="schoolId">
            <el-select v-model="userRoleList" multiple placeholder="请选择角色">
                <span v-for="(item, index) in roleList" :key="index">
                    <el-option :label="item.roleName" :value="item.id" :key="index"></el-option>
                </span>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitSetRole">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getRoleList, getRoleByUserId, setUserRole } from '@/api/login'

export default {
  props: {
    dialogData: {
      type: Object
    }
  },
  data () {
    return {
      roleList: [],
      userRoleList: []
    }
  },
  watch: {
    'dialogData.isOpen' (data) {
      if (data) {
        this.getRoleList()
      }
    }
  },
  methods: {
    /* 获取角色列表 */
    getRoleList () {
      getRoleList({
        currentPage: 1,
        pageSize: 20
      }).then(res => {
        this.roleList = res.data.data
        this.getRoleByUserId()
      })
    },
    /* 获取当前用户角色 */
    getRoleByUserId () {
      getRoleByUserId(this.dialogData.id).then(res => {
        if (res.data.length !== 0) {
          const roleValue = [...this.roleList].filter(x => [...res.data].some(y => x.id === y.roleId))
          this.userRoleList = roleValue.map((item) => {
            return item.id
          })
        } else {
          this.roleValue = []
        }
      })
    },
    /* 设置角色 */
    submitSetRole () {
      if (this.userRoleList.length !== 0) {
        setUserRole({ roleId: this.userRoleList, userId: this.dialogData.id }).then(res => {
          this.$emit('closeDialog', { isOpen: false, type: 'success' })
          this.$message({
            message: '添加成功',
            type: 'success'
          })
        })
      } else {
        this.$emit('closeDialog', { isOpen: false })
      }
    },
    /* 关闭弹窗 */
    handleClose () {
      this.$emit('closeDialog', { isOpen: false })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-coantainer {
  ::v-deep .el-dialog {
    &__body {
      max-height: 500px;
      overflow: auto;
    }
  }
}
</style>
